{/* Previous imports remain unchanged */}
import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate, Link as RouterLink } from 'react-router-dom';
import { Hidden, Grid, Table, TableHead, TableBody, TableRow, TableCell, Modal, Paper, Button, Typography, Box, Skeleton, Breadcrumbs, Link, List, ListItem, ListItemIcon, ListItemText, IconButton, Chip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { format } from 'date-fns';

const documentTypeLabels = {
  other: 'Other Document',
  passport: 'Passport',
  id_card: 'ID Card',
  residence_permit: 'Residence Permit',
  utility_bill: 'Utility Bill',
  address: 'Address information',
};

const ShowClient = ({ handleError, user }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(true);
  const [documents, setDocuments] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openAddressModal, setOpenAddressModal] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [documentIdToDelete, setDocumentIdToDelete] = useState(null);

  useEffect(() => {
    fetchClient();
    fetchDocuments();
  }, [id]);

  useEffect(() => {
    const timer = setInterval(() => {
      setDocuments(prevDocuments => 
        prevDocuments.map(doc => {
          if (doc.document_type === 'address' && doc.remaining_time > 0) {
            return { ...doc, remaining_time: doc.remaining_time - 1 };
          }
          return doc;
        })
      );
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const fetchClient = async () => {
    try {
      const response = await fetch(`/api/v1/clients/${id}`);
      const data = await response.json();
      setClient(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching client:', error);
      setLoading(false);
    }
  };

  const fetchDocuments = async () => {
    try {
      const response = await fetch(`/api/v1/clients/${id}/documents`);
      const data = await response.json();
      setDocuments(data);
    } catch (error) {
      console.error('Error fetching documents:', error);
    }
  };

  const handleOpenDocument = async (documentId) => {
    if (user.role === 'agent') return;
    try {
      const response = await fetch(`/api/v1/clients/${id}/documents/${documentId}`);
      const data = await response.json();
      setSelectedDocument(data);
      setOpenModal(true);
    } catch (error) {
      console.error('Error fetching document details:', error);
    }
  };

  const handleOpenAddressDocument = useCallback(async (documentId) => {
    try {
      const response = await fetch(`/api/v1/clients/${id}/documents/${documentId}`);
      if (response.status === 403) {
        console.error('Access forbidden');
        handleError('You do not have permission to access this document.');
        return;
      }
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setSelectedDocument(data);
      setOpenAddressModal(true);
      if (user.role === 'agent' && data.remaining_time) {
        setDocuments(prevDocuments =>
          prevDocuments.map(doc =>
            doc.id === documentId ? { ...doc, remaining_time: data.remaining_time } : doc
          )
        );
      }
    } catch (error) {
      console.error('Error fetching document details:', error);
      handleError('An error occurred while fetching the document. Please try again.');
    }
  }, [id, user.role, handleError]);

  const handleDeleteDocument = (documentId) => {
    setDocumentIdToDelete(documentId);
    setOpenConfirmationModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
      const response = await fetch(`/api/v1/clients/${id}/documents/${documentIdToDelete}`, {
        method: 'DELETE',
        headers: {
          'X-CSRF-Token': csrfToken,
        },
      });
      fetchDocuments();
      setOpenConfirmationModal(false);
      if (!response.ok) {
        handleError(`Error deleting document`);
      }
    } catch (error) {
      handleError(`Error deleting document: ${error}`);
    }
  };

  const drivingLicenseDocuments = documents.filter(
    (document) => document.document_type === 'driving_license'
  );
  const otherDocuments = documents.filter(
    (document) => (document.document_type !== 'driving_license' && document.document_type !== 'address')
  );
  const addressDocuments = documents.filter(
    (document) => document.document_type === 'address'
  );

  // Check if any address_details_1 fields are filled
  const hasAddress1 = addressDocuments.some(doc => 
    [
      'address_details_1_street',
      'address_details_1_street_2',
      'address_details_1_city',
      'address_details_1_country',
      'address_details_1_postal_code',
      'address_details_1_state',
      'address_details_1_email'
    ].some(field => doc[field] && doc[field].trim() !== '')
  );

  // Check if any address_details_2 fields are filled
  const hasAddress2 = addressDocuments.some(doc => 
    [
      'address_details_2_street',
      'address_details_2_street_2',
      'address_details_2_city',
      'address_details_2_country',
      'address_details_2_postal_code',
      'address_details_2_state',
      'address_details_2_email'
    ].some(field => doc[field] && doc[field].trim() !== '')
  );

  if (loading) {
    return (
      <Box sx={{ padding: '24px 0' }}>
        <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: '1rem' }}>
          <Link component={RouterLink} to="/records" color="inherit">
            Home
          </Link>
          <Skeleton width={100} />
        </Breadcrumbs>
      
        <Typography variant="h4" align="center" gutterBottom>
          <Skeleton />
        </Typography>
        <Box sx={{ marginTop: '1rem' }}>
          <Typography variant="subtitle1"><Skeleton /></Typography>
          <Typography variant="subtitle1"><Skeleton /></Typography>
          <Typography variant="subtitle1"><Skeleton /></Typography>
          <Typography variant="subtitle1"><Skeleton /></Typography>
          <Typography variant="subtitle1"><Skeleton /></Typography>
          <Typography variant="subtitle1"><Skeleton /></Typography>
        </Box>
      </Box>
    );
  }

  if (!client) {
    return (
      <Box sx={{ padding: '24px 0' }}>
        <Paper elevation={3} sx={{ padding: '2rem' }}>
          <Typography variant="h4" align="center" gutterBottom>
            Client not found
          </Typography>
        </Paper>
      </Box>
    );
  }

  const tableHeaderStyleDesktop = {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0.17000000178813934px',
    textAlign: 'left',
    padding: '0 0 8px 0',
    border: 'none',
    color: 'rgba(0, 0, 0, 0.6)',
  };
  const tableContentsStyleDesktop = {
    fontFamily: 'Roboto',
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '32px',
    textAlign: 'left',
    padding: 0,
    border: 'none',
  };

  return (
    <Box sx={{ padding: '24px 0' }}>
      <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: '24px' }}>
        <Link component={RouterLink} to="/records" color="inherit">
          Home
        </Link>
        <Typography color="textPrimary">{client.name}</Typography>
      </Breadcrumbs>

      <Box sx={{ padding: '0 0 24px 0', marginBottom: '24px', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
        <Hidden lgDown>
          <Table sx={{ marginTop: '1rem' }}>
            <TableHead>
              <TableRow>
                <TableCell sx={tableHeaderStyleDesktop}>Name</TableCell>
                <TableCell sx={tableHeaderStyleDesktop}>Status</TableCell>
                <TableCell sx={tableHeaderStyleDesktop}>Booking ref</TableCell>
                <TableCell sx={tableHeaderStyleDesktop}>Branch</TableCell>
                <TableCell sx={tableHeaderStyleDesktop}>Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={tableContentsStyleDesktop}>{client.name}</TableCell>
                <TableCell sx={tableContentsStyleDesktop}>{client.booking_status}</TableCell>
                <TableCell sx={tableContentsStyleDesktop}>{client.booking_ref}</TableCell>
                <TableCell sx={tableContentsStyleDesktop}>{client.branch?.name}</TableCell>
                <TableCell sx={tableContentsStyleDesktop}>{client.booked_for}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Hidden>
        <Hidden lgUp>
          <Table sx={{ marginTop: '1rem' }}>  
            <TableBody>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>{client.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Status</TableCell>
                <TableCell>{client.booking_status}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Booking ref</TableCell>
                <TableCell>{client.booking_ref}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Branch</TableCell>
                <TableCell>{client.branch?.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Time</TableCell>
                <TableCell>{client.booked_for}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Hidden>
      </Box>

      {drivingLicenseDocuments.map((document) => (
        <Paper 
          key={`driving-license-${document.id}`} 
          sx={{ padding: '16px', marginBottom: '24px', border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: '12px', boxShadow: 'none' }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6">
            Driver's license
            </Typography>
            {user.role !== 'agent' && (
              <Button variant="outlined" color="info" onClick={() => handleOpenDocument(document.id)}>
                Open
              </Button>
            )}
          </Box>
          <List>
            <ListItem>
              <ListItemIcon>
                <InsertDriveFileOutlinedIcon onClick={() => handleOpenDocument(document.id)} sx={{ color: 'action.active', fontSize: 40, backgroundColor: '#0000000A', borderRadius: '50%', padding: '8px' }} />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="body1">
                  Driver's license
                </Typography>
                <Typography variant="body2" color="#00000099">{format(new Date(document.created_at), 'dd.MM.yyyy')}</Typography>
              </ListItemText>
            </ListItem>
          </List>
          {user.role !== 'agent' && (
            <Box sx={{ display: 'flex' }}>
              <Button color="secondary" variant="text" onClick={() => handleOpenDocument(document.id)} sx={{ mr: '16px' }}>Open</Button>
              <Button color="error" variant="text" onClick={() => handleDeleteDocument(document.id)}>Delete</Button>
            </Box>
          )}
        </Paper>
      ))}

      {drivingLicenseDocuments.length === 0 && <Paper sx={{ padding: '16px', marginBottom: '24px', border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: '12px', boxShadow: 'none' }}>
        <Typography variant="h6">
          Driver's license
        </Typography>
        <Typography variant="body1" gutterBottom>
          Add driver's license
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '144px', height: '80px', gap: '0px', borderRadius: '6px', border: '2px dashed #0000003B' }}>
          <Button sx={{ height: '36px' }} variant="contained" color="primary" onClick={() => navigate(`/clients/${client.id}/documents/new_license`)}>
            <AddIcon type="filled" /> Add
          </Button>
        </Box>
      </Paper>}

      {otherDocuments.map((document) => (
        <Paper
          key={document.id}
          sx={{
            padding: '16px',
            marginBottom: '24px',
            border: '1px solid rgba(0, 0, 0, 0.12)',
            borderRadius: '12px',
            boxShadow: 'none',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6">
              {documentTypeLabels[document.document_type] || 'Other Document'}
            </Typography>
            {user.role !== 'agent' && (
              <Button variant="outlined" color="info" onClick={() => handleOpenDocument(document.id)}>
                Open
              </Button>
            )}
          </Box>
          <List>
            <ListItem>
              <ListItemIcon>
                <InsertDriveFileOutlinedIcon
                  onClick={() => handleOpenDocument(document.id)}
                  sx={{
                    color: 'action.active',
                    fontSize: 40,
                    backgroundColor: '#0000000A',
                    borderRadius: '50%',
                    padding: '8px',
                  }}
                />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="body1">
                  {documentTypeLabels[document.document_type] || 'Other Document'}
                </Typography>
                <Typography variant="body2" color="#00000099">
                  {format(new Date(document.created_at), 'dd.MM.yyyy')}
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
          {user.role !== 'agent' && (
            <Box sx={{ display: 'flex' }}>
              <Button color="secondary" variant="text" onClick={() => handleOpenDocument(document.id)} sx={{ mr: '16px' }}>Open</Button>
              <Button color="error" variant="text" onClick={() => handleDeleteDocument(document.id)}>Delete</Button>
            </Box>
          )}
        </Paper>
      ))}

      <Paper sx={{ padding: '16px', marginBottom: '24px', border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: '12px', boxShadow: 'none' }}>
        <Typography variant="h6">
          Other documents
        </Typography>
        <Typography variant="body1" gutterBottom>
        Add additional documents
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '144px', height: '80px', gap: '0px', borderRadius: '6px', border: '2px dashed #0000003B' }}>
          <Button sx={{ height: '36px' }} variant="contained" color="primary" onClick={() => navigate(`/clients/${client.id}/documents/new`)}>
            <AddIcon type="filled" /> Add
          </Button>
        </Box>
      </Paper>

      {addressDocuments.map((document) => (
        <Paper
          key={document.id}
          sx={{
            padding: '16px',
            marginBottom: '24px',
            border: '1px solid rgba(0, 0, 0, 0.12)',
            borderRadius: '12px',
            boxShadow: 'none',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6">
              {document.address_details_1_street && (<>Contract holder's personal details / address</>)}
              {document.address_details_2_street && (<>Contract holder's local address (VIC Use Only)</>)}
            </Typography>
            {(user.role !== 'agent' || (user.role === 'agent' && document.remaining_time > 0)) && (
              <Button 
                variant="outlined" 
                color="info" 
                onClick={() => handleOpenAddressDocument(document.id)}
                disabled={user.role === 'agent' && document.remaining_time === 0}
              >
                Open
              </Button>
            )}
          </Box>
          <List>
            <ListItem>
              <ListItemIcon>
                <LocationOnOutlinedIcon
                  onClick={() => handleOpenAddressDocument(document.id)}
                  sx={{
                    color: 'action.active',
                    fontSize: 40,
                    backgroundColor: '#0000000A',
                    borderRadius: '50%',
                    padding: '8px',
                  }}
                />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="body1">
                  Record added
                </Typography>
                <Typography variant="body2" color="#00000099">
                  {format(new Date(document.created_at), 'dd.MM.yyyy')}
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
          {user.role === 'agent' && document.remaining_time > 0 && (
            <Typography variant="body2" color="primary">
              Viewable for {Math.floor(document.remaining_time / 60)}:{(document.remaining_time % 60).toString().padStart(2, '0')} mins remaining
            </Typography>
          )}
          {user.role !== 'agent' && (
            <Box sx={{ display: 'flex' }}>
              <Button color="secondary" variant="text" onClick={() => handleOpenAddressDocument(document.id)} sx={{ mr: '16px' }}>Open</Button>
              <Button color="error" variant="text" onClick={() => handleDeleteDocument(document.id)}>Delete</Button>
            </Box>
          )}
        </Paper>
      ))}
      
      {!hasAddress1 && (
        <>
          <Paper sx={{ padding: '16px', marginBottom: '24px', border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: '12px', boxShadow: 'none' }}>
            <Typography variant="h6">
              Contract holder's personal details / address
            </Typography>
            <Typography variant="body1" gutterBottom>
              Add address information
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '144px', height: '80px', gap: '0px', borderRadius: '6px', border: '2px dashed #0000003B' }}>
              <Button sx={{ height: '36px' }} variant="contained" color="primary" onClick={() => navigate(`/clients/${client.id}/documents/new_address`)}>
                <AddIcon type="filled" /> Add
              </Button>
            </Box>
          </Paper>
        </>
      )}

      {!hasAddress2 && (
        <>
          <Paper sx={{ padding: '16px', marginBottom: '24px', border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: '12px', boxShadow: 'none' }}>
            <Typography variant="h6">
              Contract holder's local address (VIC Use Only)
            </Typography>
            <Typography variant="body1" gutterBottom>
              Add local address information
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '144px', height: '80px', gap: '0px', borderRadius: '6px', border: '2px dashed #0000003B' }}>
              <Button sx={{ height: '36px' }} variant="contained" color="primary" onClick={() => navigate(`/clients/${client.id}/documents/new_address_2`)}>
                <AddIcon type="filled" /> Add
              </Button>
            </Box>
          </Paper>
        </>
      )}
      
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '80px', gap: '0px' }}>
        <Button sx={{ height: '36px' }} variant="contained" color="primary" onClick={() => navigate(`/records`)}>
          Complete
        </Button>
      </Box>

      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box sx={{ 
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          outline: 'none',
          width: '80%',
          borderRadius: '12px',
          maxWidth: 800,
          maxHeight: '85%',
          overflowY: 'auto',
        }}>
          <Typography variant="h6" component="h2" gutterBottom>
            Review scanned images
          </Typography>
          {selectedDocument && (
            <>
              <Box sx={{marginBottom: 2}}>
                <Typography variant="subtitle1">Document type: {selectedDocument.document_type}</Typography>
                <Typography variant="subtitle1">Name: {selectedDocument.client}</Typography>
                <Typography variant="subtitle1">Date: {new Date(selectedDocument.created_at).toLocaleDateString()}</Typography>
                <Typography variant="subtitle1">Processed by: {selectedDocument.created_by}</Typography></Box>
              <Grid container spacing={2}>
                {selectedDocument.front_photo && (
                  <Grid item xs={12} sm={6}>
                    <Box sx={{p: 0}}>
                      <img src={selectedDocument.front_photo} alt="Front photo" style={{ maxWidth: '100%' }} />
                    </Box>
                  </Grid>
                )}
                {selectedDocument.back_photo && (
                  <Grid item xs={12} sm={6}>
                    <Box sx={{p: 0}}>
                      <img src={selectedDocument.back_photo} alt="Back photo" style={{ maxWidth: '100%' }} />
                    </Box>  
                  </Grid>
                )}
                {selectedDocument.photo && (
                  <Grid item xs={12} sm={12}>
                    <Box sx={{p: 0}}>
                      <img src={selectedDocument.photo} alt="Photo" style={{ maxWidth: '100%' }} />
                    </Box>  
                  </Grid>
                )}
              </Grid>
              <Box sx={{marginTop: 2, textAlign: 'right'}}>
                <Button variant="text" onClick={() => setOpenModal(false)} color="secondary">OK</Button>
              </Box>
            </>
          )}
        </Box>
      </Modal>

      <Modal open={openAddressModal} onClose={() => setOpenAddressModal(false)}>
        <Box sx={{ 
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          outline: 'none',
          width: '80%',
          borderRadius: '12px',
          maxWidth: 800,
          maxHeight: '85%',
          overflowY: 'auto',
        }}>
          <Typography variant="h6" component="h2" gutterBottom>
            Review personal details / address
          </Typography>
          {selectedDocument && (
            <>
              {selectedDocument.address_details_1 && selectedDocument.address_details_1.address_details_1_street && (
                <>
                  <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>Contract holder's personal details / address</Typography>
                  <Box sx={{marginBottom: 2}}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={4}>
                        <Typography variant="h6">Address line</Typography>
                        <Typography variant="subtitle1">{selectedDocument.address_details_1.address_details_1_street}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography variant="h6">Address line 2</Typography>
                        <Typography variant="subtitle1">{selectedDocument.address_details_1.address_details_1_street_2}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography variant="h6">City / Town / District</Typography>
                        <Typography variant="subtitle1">{selectedDocument.address_details_1.address_details_1_city}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography variant="h6">Country</Typography>
                        <Typography variant="subtitle1">{selectedDocument.address_details_1.address_details_1_country}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography variant="h6">Postal Code / ZIP</Typography>
                        <Typography variant="subtitle1">{selectedDocument.address_details_1.address_details_1_postal_code}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography variant="h6">State / Province / Region</Typography>
                        <Typography variant="subtitle1">{selectedDocument.address_details_1.address_details_1_state}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography variant="h6">Email</Typography>
                        <Typography variant="subtitle1">{selectedDocument.address_details_1.address_details_1_email}</Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              )}

              {selectedDocument.address_details_2 && selectedDocument.address_details_2.address_details_2_street && (
                <>
                  <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>Contract holder's local address (VIC Use Only)</Typography>
                  <Box sx={{marginBottom: 2}}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={4}>
                        <Typography variant="h6">Address line</Typography>
                        <Typography variant="subtitle1">{selectedDocument.address_details_2.address_details_2_street}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography variant="h6">Address line 2</Typography>
                        <Typography variant="subtitle1">{selectedDocument.address_details_2.address_details_2_street_2}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography variant="h6">City / Town / District</Typography>
                        <Typography variant="subtitle1">{selectedDocument.address_details_2.address_details_2_city}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography variant="h6">Country</Typography>
                        <Typography variant="subtitle1">{selectedDocument.address_details_2.address_details_2_country}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography variant="h6">Postal Code / ZIP</Typography>
                        <Typography variant="subtitle1">{selectedDocument.address_details_2.address_details_2_postal_code}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography variant="h6">State / Province / Region</Typography>
                        <Typography variant="subtitle1">{selectedDocument.address_details_2.address_details_2_state}</Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              )}

              <Box sx={{marginBottom: 2}}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="h6">Name</Typography>
                    <Typography variant="subtitle1">{selectedDocument.client}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="h6">Date</Typography>
                    <Typography variant="subtitle1">{new Date(selectedDocument.created_at).toLocaleDateString()}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="h6">Processed by</Typography>
                    <Typography variant="subtitle1">{selectedDocument.created_by}</Typography>
                  </Grid>
                </Grid>
              </Box>
            
              <Box sx={{marginTop: 2, textAlign: 'right'}}>
                {user.role !== 'agent' && (
                  <>
                    {selectedDocument.address_details_1 && selectedDocument.address_details_1.address_details_1_street && (
                      <Button 
                        variant="outlined" 
                        color="primary" 
                        sx={{ mr: 2 }}
                        onClick={() => {
                          setOpenAddressModal(false);
                          navigate(`/clients/${id}/documents/${selectedDocument.id}/edit_address`);
                        }}
                      >
                        Edit Address 1
                      </Button>
                    )}
                    {selectedDocument.address_details_2 && selectedDocument.address_details_2.address_details_2_street && (
                      <Button 
                        variant="outlined" 
                        color="primary" 
                        sx={{ mr: 2 }}
                        onClick={() => {
                          setOpenAddressModal(false);
                          navigate(`/clients/${id}/documents/${selectedDocument.id}/edit_address_2`);
                        }}
                      >
                        Edit Address 2
                      </Button>
                    )}
                  </>
                )}
                <Button variant="text" onClick={() => setOpenAddressModal(false)} color="secondary">OK</Button>
              </Box>
            </>
          )}
          {selectedDocument && !selectedDocument.address_details_1 && !selectedDocument.address_details_2 && (
            <Typography variant="body1">
              Address details are not available for this document.
            </Typography>
          )}
        </Box>
      </Modal>

      <Modal open={openConfirmationModal} onClose={() => setOpenConfirmationModal(false)}>
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          outline: 'none',
          width: '400px',
          maxWidth: '100%',
        }}>
          <Typography variant="h6" component="h2" gutterBottom>
            Confirm Delete
          </Typography>
          <Typography variant="body1" gutterBottom>
            Are you sure you want to delete this record?
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button variant="outlined" onClick={() => setOpenConfirmationModal(false)} sx={{ mr: 1 }}>
              Cancel
            </Button>
            <Button variant="contained" color="error" onClick={handleConfirmDelete}>
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default ShowClient;
