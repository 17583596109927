// app/javascript/components/AddDocument.js
import React, { useState, useRef, useEffect } from 'react';
import { useParams, useNavigate, Link as RouterLink } from 'react-router-dom';
import { 
  Hidden, 
  Grid, 
  Table, 
  TableHead, 
  TableBody, 
  TableRow, 
  TableCell, 
  Breadcrumbs, 
  Link, 
  Paper, 
  Typography, 
  Button, 
  IconButton, 
  Box, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem,
} from '@mui/material';
import { PhotoCamera, Close } from '@mui/icons-material';
import RefreshIcon from '@mui/icons-material/Refresh';
import CheckIcon from '@mui/icons-material/Check';
import { format } from 'date-fns';

const AddDocument = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const overlayRef = useRef(null);
  const [client, setClient] = useState(null);
  const [step, setStep] = useState(1);
  const [image, setImage] = useState(null);
  const [stream, setStream] = useState(null);
  const [videoAspectRatio, setVideoAspectRatio] = useState(null);
  const [overlayDimensions, setOverlayDimensions] = useState({ width: '80%', height: '75%' });
  const [documentType, setDocumentType] = useState('other');
  const [savingDocument, setSavingDocument] = useState(false);

  const handleDocumentTypeChange = (e) => {
    setDocumentType(e.target.value);
  };

  const fetchClient = async () => {
    try {
      const response = await fetch(`/api/v1/clients/${id}`);
      const data = await response.json();
      setClient(data);
    } catch (error) {
      console.error('Error fetching client:', error);
    }
  };

  useEffect(() => {
    document.body.classList.add('hide-header');
  
    return () => {
      document.body.classList.remove('hide-header');
    };
  }, []);

  useEffect(() => {
    fetchClient();
  }, [id]);

  useEffect(() => {
    async function enableCamera() {
      const constraints = {
        video: {
          facingMode: 'environment',
          width: { ideal: 1920 },
          height: { ideal: 1080 },
        },
      };

      try {
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        videoRef.current.srcObject = stream;
        setStream(stream);
      } catch (error) {
        console.error('Error accessing camera:', error);
      }
    }

    if (step === 1) {
      enableCamera();
    }

    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [step]);

  // to unload the camera on page leave
  useEffect(() => {
    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [stream]);

  useEffect(() => {
    const handleLoadedMetadata = () => {
      calculateAspectRatio();
    };

    const calculateAspectRatio = () => {
      if (videoRef.current) {
        const videoWidth = videoRef.current.videoWidth;
        const videoHeight = videoRef.current.videoHeight;
        const aspectRatio = videoWidth / videoHeight;
        setVideoAspectRatio(aspectRatio);
      }

      // Check if the device is an iPad
      const isIPad = /Macintosh/i.test(navigator.userAgent);
      if (('ontouchstart' in window) || (navigator.MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0)) {
        if (isIPad) {
          setOverlayDimensions({ width: '99%', height: '99%' });      
        } 
      } else {
        console.log("This is not an iPad device!");
      }
    };

    const handleResize = () => {
      calculateAspectRatio();
    };

    if (videoRef.current) {
      videoRef.current.addEventListener('loadedmetadata', handleLoadedMetadata);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('loadedmetadata', handleLoadedMetadata);
      }
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const captureImage = () => {
    const canvas = document.createElement('canvas');
    const videoWidth = videoRef.current.videoWidth;
    const videoHeight = videoRef.current.videoHeight;

    const overlayWidth = parseFloat(overlayDimensions.width) / 100;
    const overlayHeight = parseFloat(overlayDimensions.height) / 100;

    const fragmentWidth = videoWidth * overlayWidth;
    const fragmentHeight = videoHeight * overlayHeight;

    const offsetX = (videoWidth - fragmentWidth) / 2;
    const offsetY = (videoHeight - fragmentHeight) / 2;

    canvas.width = fragmentWidth;
    canvas.height = fragmentHeight;

    canvas.getContext('2d').drawImage(
      videoRef.current,
      offsetX,
      offsetY,
      fragmentWidth,
      fragmentHeight,
      0,
      0,
      fragmentWidth,
      fragmentHeight
    );

    const imageData = canvas.toDataURL('image/jpeg', 0.8);
    setImage(imageData);
    setStep(2);
  };

  const retakeImage = () => {
    setImage(null);
    setStep(1);
  };

  const handleSubmit = async () => {
    setSavingDocument(true);
    const formData = new FormData();
    formData.append('document[document_type]', documentType);
    formData.append('photo', dataURItoBlob(image));

    try {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
      const response = await fetch(`/api/v1/clients/${id}/documents`, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': csrfToken,
        },
        body: formData,
      });

      if (response.ok) {
        navigate(`/clients/${id}`);
      } else {
        console.error('Error creating document:', response);
      }
    } catch (error) {
      console.error('Error creating document:', error);
    } finally {
      setSavingDocument(false);
    }
  };

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const tableHeaderStyleDesktop = {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0.17000000178813934px',
    textAlign: 'left',
    padding: '0 0 8px 0',
    border: 'none',
    color: 'rgba(0, 0, 0, 0.6)',
  };
  const tableContentsStyleDesktop = {
    fontFamily: 'Roboto',
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '32px',
    textAlign: 'left',
    padding: 0,
    border: 'none',
  };

  const FooterButtons = ({ step, onCapture, onRetake, onKeep }) => {
    return (
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          padding: 2,
          backgroundColor: 'white',
          boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.1)',
          zIndex: 2000,
          display: { xs: 'flex', md: 'none' },
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {step === 1 ? (
          <Button variant="contained" sx={{ height: '64px' }} onClick={onCapture} startIcon={<PhotoCamera />}>
            Capture Image
          </Button>
        ) : (
          <></>
        )}
      </Box>
    );
  };

  return (
    <Box sx={{ padding: '24px 0' }}>
      <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: '24px' }}>
        <Link component={RouterLink} to="/records" color="inherit">
          Home
        </Link>
        {client && <Link component={RouterLink} to={`/clients/${id}`} color="inherit">
          {client.name}
        </Link> }
        <Typography color="textPrimary">Other Document</Typography>
      </Breadcrumbs>

      {client && step === 2 && (<Box sx={{ padding: '0 0 24px 0', marginBottom: '24px', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
        <Hidden lgDown>
          <Table sx={{ marginTop: '1rem' }}>
            <TableHead>
              <TableRow>
                <TableCell sx={tableHeaderStyleDesktop}>Name</TableCell>
                <TableCell sx={tableHeaderStyleDesktop}>Booking ref</TableCell>
                <TableCell sx={tableHeaderStyleDesktop}>Branch</TableCell>
                <TableCell sx={tableHeaderStyleDesktop}>Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={tableContentsStyleDesktop}>{client.name}</TableCell>
                <TableCell sx={tableContentsStyleDesktop}>{client.booking_ref}</TableCell>
                <TableCell sx={tableContentsStyleDesktop}>{client.branch?.name}</TableCell>
                <TableCell sx={tableContentsStyleDesktop}>{client.booked_for}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Hidden>
        <Hidden lgUp>
          <Table sx={{ marginTop: '1rem' }}>  
            <TableBody>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>{client.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Booking ref</TableCell>
                <TableCell>{client.booking_ref}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Branch</TableCell>
                <TableCell>{client.branch?.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Time</TableCell>
                <TableCell>{client.booked_for}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Hidden>
      </Box>
      )}

      <Paper sx={{ padding: '0', borderRadius: '12px' }} position="relative">
        {step === 1 && (
          <Box position="relative">
            <Box
              position="relative"
              top={0}
              left={0}
              right={0}
              bottom={0}
              display="flex"
              alignItems="center"
              justifyContent="center"
              style={{ borderRadius: '12px', overflow: 'hidden' }}
            >
              <Box
                sx={{
                  position: 'relative',
                  width: '100%',
                  height: 0,
                  paddingBottom: videoAspectRatio ? `${(1 / videoAspectRatio) * 100}%` : '56.25%',
                  borderRadius: '12px', overflow: 'hidden',
                }}
              >
                <video
                  ref={videoRef}
                  autoPlay
                  playsInline
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                />
                <Box
                  position="absolute"
                  top={0}
                  left={0}
                  right={0}
                  padding={2}
                  color="white"
                  sx={{ display: 'flex', height: 'calc((100% - '+overlayDimensions.height+')/ 2)', textAlign: 'center', justifyContent: 'center', alignItems: 'center', zIndex: 2000 }}
                >
                  <Typography variant="h6">
                    Capture the document
                  </Typography>
                  <Box
                    position="absolute"
                    sx={{ top: '32px', right: '32px' }}
                  >
                    <IconButton
                      color="inherit"
                      onClick={() => navigate(`/clients/${id}`)}
                      style={{ backgroundColor: 'rgba(255, 255, 255, 0.08)' }}
                    >
                      <Close />
                    </IconButton>
                  </Box>
                </Box>
                <Box
                  position="absolute"
                  bottom={0}
                  left={0}
                  right={0}
                  padding={2}
                  display="flex"
                  justifyContent="center"
                  sx={{ display: { xs: 'none', md: 'flex' }, height: 'calc((100% - '+overlayDimensions.height+')/ 2)', justifyContent: 'center', alignItems: 'center', zIndex: 2000 }}
                >
                  <Button variant="contained" sx={{ height: "42px" }} onClick={captureImage} startIcon={<PhotoCamera />}>
                    Capture Image
                  </Button>
                </Box>
                <Box
                  ref={overlayRef}
                  position="absolute"
                  top="50%"
                  left="50%"
                  style={{
                    transform: 'translate(-50%, -50%)',
                    width: overlayDimensions.width,
                    height: overlayDimensions.height,
                    borderRadius: '18px',
                    zIndex: 2,
                    boxShadow: '0 0 0 3600px rgba(0, 0, 0, 0.5)',
                  }}
                >
                  <Box
                    position="absolute"
                    top={0}
                    left={0}
                    right={0}
                    bottom={0}
                    borderRadius="18px"
                    bgcolor="transparent"
                    border="2px solid white"
                  />
                </Box>
              </Box>
            </Box>
            <FooterButtons step={step} onCapture={captureImage} onRetake={retakeImage} />
          </Box>
        )}
        {step === 2 && (
          <Box
            sx={{
              bgcolor: { xs: 'transparent', sm: '#FFFFFF' },
              borderRadius: '12px',
              padding: { xs: '16px 4px', sm: '16px' },
              position: 'relative',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, textAlign: { xs: 'center', sm: 'left' } }}>
              <Box sx={{ width: { xs: '100%', sm: 'auto' }}}>
                <Typography variant="h6">Ensure all details are readable</Typography>
                <Typography variant="body2">Retake photo if clarity is needed</Typography>
              </Box>
              <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <Button variant="contained" color="primary" onClick={handleSubmit} startIcon={<CheckIcon />} disabled={savingDocument}>
                  {savingDocument ? 'Saving...' : 'Confirm & Save'}
                </Button>
              </Box>
            </Box>
            <Box
              sx={{
                width: overlayDimensions.width,
                borderRadius: '12px',
                border: '2px solid black',
                overflow: 'hidden',
                margin: { xs: '12px auto', sm: '32px auto' }
              }}
            >
              <img src={image} alt="Document" style={{ display: 'block', width: '100%' }} />
            </Box>
            <FormControl variant="outlined" sx={{ display: 'flex', mt: 2, width: '200px', mr: 'auto', ml: 'auto' }}>
              <InputLabel id="document-type-label">Document Type</InputLabel>
              <Select
                labelId="document-type-label"
                value={documentType}
                onChange={handleDocumentTypeChange}
                label="Document Type"
              >
                <MenuItem value="other">Other</MenuItem>
                <MenuItem value="passport">Passport</MenuItem>
                <MenuItem value="id_card">ID Card</MenuItem>
                <MenuItem value="utility_bill">Utility Bill</MenuItem>
              </Select>
            </FormControl>

            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <Button variant="outlined" color="info" onClick={retakeImage} sx={{ mr: 'auto', ml: 'auto' }}>
                <RefreshIcon sx={{ mr: 1 }} />
                Retake Image
              </Button>
            </Box>
            <Box sx={{ display: { xs: 'flex', sm: 'none' }, justifyContent: 'center', mt: 2 }}>
              <Button variant="contained" color="primary" onClick={handleSubmit} startIcon={<CheckIcon />} disabled={savingDocument}>
                {savingDocument ? 'Saving...' : 'Confirm & Save'}
              </Button>
            </Box>
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default AddDocument;