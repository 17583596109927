import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link as RouterLink } from 'react-router-dom';
import { 
  Hidden, 
  Grid, 
  Table, 
  TableHead, 
  TableBody, 
  TableRow, 
  TableCell, 
  Breadcrumbs, 
  Link, 
  Paper, 
  Typography, 
  Button, 
  Box, 
  TextField,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

const AddAddress = ({ handleError }) => {
  const { id, documentId } = useParams();
  const navigate = useNavigate();
  const [client, setClient] = useState(null);
  const [savingAddress, setSavingAddress] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isLocalAddress, setIsLocalAddress] = useState(false);

  // First address block fields
  const [address1StreetAddress, setAddress1StreetAddress] = useState('');
  const [address1StreetAddress2, setAddress1StreetAddress2] = useState('');
  const [address1City, setAddress1City] = useState('');
  const [address1Country, setAddress1Country] = useState('');
  const [address1PostalCode, setAddress1PostalCode] = useState('');
  const [address1State, setAddress1State] = useState('');
  const [address1Email, setAddress1Email] = useState('');
  const [emailError, setEmailError] = useState('');

  // Second address block fields
  const [address2StreetAddress, setAddress2StreetAddress] = useState('');
  const [address2StreetAddress2, setAddress2StreetAddress2] = useState('');
  const [address2City, setAddress2City] = useState('');
  const [address2Country, setAddress2Country] = useState('');
  const [address2PostalCode, setAddress2PostalCode] = useState('');
  const [address2State, setAddress2State] = useState('');

  useEffect(() => {
    fetchClient();
    if (documentId) {
      setIsEditing(true);
      fetchDocument();
    }
    // Check if we're adding/editing local address based on URL
    setIsLocalAddress(window.location.pathname.includes('new_address_2') || window.location.pathname.includes('edit_address_2'));
  }, [id, documentId]);

  const fetchClient = async () => {
    try {
      const response = await fetch(`/api/v1/clients/${id}`);
      const data = await response.json();
      setClient(data);
    } catch (error) {
      handleError("Error fetching client: #{error}");
    }
  };

  const fetchDocument = async () => {
    try {
      const response = await fetch(`/api/v1/clients/${id}/documents/${documentId}`);
      const data = await response.json();
      
      if (data.address_details_1) {
        setAddress1StreetAddress(data.address_details_1.address_details_1_street || '');
        setAddress1StreetAddress2(data.address_details_1.address_details_1_street_2 || '');
        setAddress1City(data.address_details_1.address_details_1_city || '');
        setAddress1Country(data.address_details_1.address_details_1_country || '');
        setAddress1PostalCode(data.address_details_1.address_details_1_postal_code || '');
        setAddress1State(data.address_details_1.address_details_1_state || '');
        setAddress1Email(data.address_details_1.address_details_1_email || '');
      }

      if (data.address_details_2) {
        setAddress2StreetAddress(data.address_details_2.address_details_2_street || '');
        setAddress2StreetAddress2(data.address_details_2.address_details_2_street_2 || '');
        setAddress2City(data.address_details_2.address_details_2_city || '');
        setAddress2Country(data.address_details_2.address_details_2_country || '');
        setAddress2PostalCode(data.address_details_2.address_details_2_postal_code || '');
        setAddress2State(data.address_details_2.address_details_2_state || '');
      }
    } catch (error) {
      console.error('Error fetching document:', error);
    }
  };

  const validateEmail = (email) => {
    if (email === '') {
      setEmailError('');
      return true;
    }
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isValid = re.test(String(email).toLowerCase());
    setEmailError(isValid ? '' : 'Invalid email address');
    return isValid;
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setAddress1Email(email);
    validateEmail(email);
  };

  const tableHeaderStyleDesktop = {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0.17000000178813934px',
    textAlign: 'left',
    padding: '0 0 8px 0',
    border: 'none',
    color: 'rgba(0, 0, 0, 0.6)',
  };
  const tableContentsStyleDesktop = {
    fontFamily: 'Roboto',
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '32px',
    textAlign: 'left',
    padding: 0,
    border: 'none',
  };

  const handleSubmit = async () => {
    if (!isLocalAddress && !validateEmail(address1Email)) {
      return;
    }

    setSavingAddress(true);
    const formData = new FormData();
    formData.append('document[document_type]', 'address');

    if (!isLocalAddress) {
      // First address block
      formData.append('document[address_details_1_street]', address1StreetAddress);
      formData.append('document[address_details_1_street_2]', address1StreetAddress2);
      formData.append('document[address_details_1_city]', address1City);
      formData.append('document[address_details_1_country]', address1Country);
      formData.append('document[address_details_1_postal_code]', address1PostalCode);
      formData.append('document[address_details_1_state]', address1State);
      formData.append('document[address_details_1_email]', address1Email);
    } else {
      // Second address block
      formData.append('document[address_details_2_street]', address2StreetAddress);
      formData.append('document[address_details_2_street_2]', address2StreetAddress2);
      formData.append('document[address_details_2_city]', address2City);
      formData.append('document[address_details_2_country]', address2Country);
      formData.append('document[address_details_2_postal_code]', address2PostalCode);
      formData.append('document[address_details_2_state]', address2State);
    }

    try {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
      const url = isEditing
        ? `/api/v1/clients/${id}/documents/${documentId}`
        : `/api/v1/clients/${id}/documents`;
      const method = isEditing ? 'PUT' : 'POST';
      
      const response = await fetch(url, {
        method,
        headers: {
          'X-CSRF-Token': csrfToken,
        },
        body: formData,
      });

      if (response.ok) {
        navigate(`/clients/${id}`);
      } else {
        console.error('Error creating/updating document:', response);
      }
    } catch (error) {
      console.error('Error creating/updating document:', error);
    } finally {
      setSavingAddress(false);
    }
  };

  return (
    <Box sx={{ padding: '24px 0' }}>
      <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: '24px' }}>
        <Link component={RouterLink} to="/records" color="inherit">
          Home
        </Link>
        {client && <Link component={RouterLink} to={`/clients/${id}`} color="inherit">
          {client.name}
        </Link> }
        <Typography color="textPrimary">
          {isEditing ? 'Edit Address' : 'Add Address'}
        </Typography>
      </Breadcrumbs>

      {client && (<Box sx={{ padding: '0 0 24px 0', marginBottom: '24px', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
        <Hidden lgDown>
          <Table sx={{ marginTop: '1rem' }}>
            <TableHead>
              <TableRow>
                <TableCell sx={tableHeaderStyleDesktop}>Name</TableCell>
                <TableCell sx={tableHeaderStyleDesktop}>Booking ref</TableCell>
                <TableCell sx={tableHeaderStyleDesktop}>Branch</TableCell>
                <TableCell sx={tableHeaderStyleDesktop}>Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={tableContentsStyleDesktop}>{client.name}</TableCell>
                <TableCell sx={tableContentsStyleDesktop}>{client.booking_ref}</TableCell>
                <TableCell sx={tableContentsStyleDesktop}>{client.branch?.name}</TableCell>
                <TableCell sx={tableContentsStyleDesktop}>{client.booked_for}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Hidden>
        <Hidden lgUp>
          <Table sx={{ marginTop: '1rem' }}>  
            <TableBody>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>{client.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Booking ref</TableCell>
                <TableCell>{client.booking_ref}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Branch</TableCell>
                <TableCell>{client.branch?.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Time</TableCell>
                <TableCell>{client.booked_for}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Hidden>
      </Box>
      )}

      <Paper sx={{ padding: '16px', borderRadius: '12px' }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          {isLocalAddress ? "Contract holder's local address (VIC Use Only)" : "Contract holder's personal details / address"}
        </Typography>
        
        <Box sx={{ borderRadius: '6px', mt: 2 }}>
          <Grid container spacing={2} justifyContent="space-between" alignItems="left">
            {!isLocalAddress ? (
              // First address block fields
              <>
                <Grid item xs={12} sm={4}>
                  <TextField label="Address line 1" fullWidth margin="dense" value={address1StreetAddress} onChange={(e) => setAddress1StreetAddress(e.target.value)} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField label="Address line 2" fullWidth margin="dense" value={address1StreetAddress2} onChange={(e) => setAddress1StreetAddress2(e.target.value)} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField label="City / Town / District" fullWidth margin="dense" value={address1City} onChange={(e) => setAddress1City(e.target.value)} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField label="Country" fullWidth margin="dense" value={address1Country} onChange={(e) => setAddress1Country(e.target.value)} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField label="Postal Code / ZIP" fullWidth margin="dense" value={address1PostalCode} onChange={(e) => setAddress1PostalCode(e.target.value)} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField label="State / Province / Region" fullWidth margin="dense" value={address1State} onChange={(e) => setAddress1State(e.target.value)} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField 
                    label="Email" 
                    fullWidth 
                    margin="dense" 
                    value={address1Email} 
                    onChange={handleEmailChange}
                    error={!!emailError}
                    helperText={emailError}
                  />
                </Grid>
              </>
            ) : (
              // Second address block fields
              <>
                <Grid item xs={12} sm={4}>
                  <TextField label="Address line 1" fullWidth margin="dense" value={address2StreetAddress} onChange={(e) => setAddress2StreetAddress(e.target.value)} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField label="Address line 2" fullWidth margin="dense" value={address2StreetAddress2} onChange={(e) => setAddress2StreetAddress2(e.target.value)} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField label="City / Town / District" fullWidth margin="dense" value={address2City} onChange={(e) => setAddress2City(e.target.value)} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField label="Country" fullWidth margin="dense" value={address2Country} onChange={(e) => setAddress2Country(e.target.value)} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField label="Postal Code / ZIP" fullWidth margin="dense" value={address2PostalCode} onChange={(e) => setAddress2PostalCode(e.target.value)} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField label="State / Province / Region" fullWidth margin="dense" value={address2State} onChange={(e) => setAddress2State(e.target.value)} />
                </Grid>
              </>
            )}
          </Grid>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Button variant="contained" color="primary" onClick={handleSubmit} startIcon={<CheckIcon />} disabled={savingAddress}>
            {savingAddress ? 'Saving...' : (isEditing ? 'Update Address' : 'Save Address')}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default AddAddress;
